/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'pxt2WhfREe61a4aZ2mMb5P.png': { uri: '/images/pxt2WhfREe61a4aZ2mMb5P.png' },
'6pdphFPCiETF6iYdZ2529n.png': { uri: '/images/6pdphFPCiETF6iYdZ2529n.png' },
't2LuXbhy4JPTYEr3pKj7K5.png': { uri: '/images/t2LuXbhy4JPTYEr3pKj7K5.png' },
'5thCxCEfSAjNmBjxG1afwo.png': { uri: '/images/5thCxCEfSAjNmBjxG1afwo.png' }
}

export default imageStaticSourcesByFileName
