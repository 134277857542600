import React, { useRef, useEffect } from 'react'
import { TextInput } from 'react-native-web'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'

const ControlTextInput: AppAtomRenders['TextInput'] = ({
    isFocused,
    editable,
    style,
    keyboardType,
    placeholder,
    multiline,
    maxLength,
    placeholderTextColor,
    value,
    secureTextEntry,
    returnKeyType,
    blurOnSubmit,
    onSubmitEditing,
    onBlur,
    onFocus,
    onChangeText,
}) => {
    const inputRef = useRef<typeof TextInput | null>(null)

    useEffect(() => {
        if (isFocused && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isFocused])

    return (
        <TextInput
            ref={inputRef}
            style={style}
            keyboardType={keyboardType}
            placeholder={placeholder}
            multiline={multiline}
            maxLength={maxLength}
            placeholderTextColor={placeholderTextColor}
            value={value}
            secureTextEntry={secureTextEntry}
            returnKeyType={returnKeyType}
            blurOnSubmit={blurOnSubmit}
            editable={editable}
            onSubmitEditing={onSubmitEditing}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onBlur={(event: any) => {
                window.scrollTo(0, 0)
                onBlur?.(event)
            }}
            onFocus={onFocus}
            onChangeText={onChangeText}
        />
    )
}

export default ControlTextInput
